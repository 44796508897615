import React, { useState, useEffect } from "react";

import star from "./../../static/img/star.png";
import notri from "./../../static/img/notriagle.png";
import wave from "./../../static/img/waves.png";
import shade from "./../../static/img/shade.png";
import { BsArrowDown } from "react-icons/bs";

function Hero() {
  const ref2 = React.useRef();
  const windowHeight = window.innerHeight;
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
    ref2.current.style.backgroundColor = `rgba(48, 48, 48, ${
      0.2 + (position / 100) * 0.5
    })`;
  };


  async function scrollTo(x, y) {
    window.scrollTo(x, y);
  }

  useEffect(() => {
    console.log("Hero OffsetHeight: ", ref2.current.offsetHeight);
    window.scrollTo(0, 0);

    ref2.current.style.backgroundColor = `rgba(48, 48, 48, 0.2)`;
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log("Hero: ", scrollPosition);

  return (
    <div
      className="row Hero"
      style={{ height: "100vh", maxWidth: "100vw" }}
      ref={ref2}
    >
      <div className="col">
        <div className="sticky-top">
          <table style={{ height: "100vh", width: "100vw" }}>
            <tbody>
              <tr>
                <td className="align-top">
                  {/* top */}
                  <div className="row">
                    <div className="col">
                      <h6>D</h6>
                      <h6>K</h6>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>
                  {/* middle */}
                  <div className="row">
                    {/* left */}
                    <div className="col-2 align-self-center  ">
                      <div
                        className="row justify-content-end"
                        data-aos="fade-down"
                        data-aos-delay="1400"
                        data-aos-duration="600"
                        data-aos-offset="0"
                        data-aos-easing="linear"
                      >
                        <div className="col-sm-2 ">
                          <img src={star} alt="" height={"20px"} />
                        </div>
                        <div className="col-sm-2">
                          {" "}
                          <img src={shade} alt="" height={"20px"} />
                        </div>
                      </div>
                    </div>
                    {/* center */}
                    <div className="col-sm align-self-center">
                      <h1 className="HeroTextBig ">
                        <span
                          data-aos="fade-down"
                          data-aos-delay="1000"
                          data-aos-duration="600"
                          data-aos-offset="0"
                          data-aos-easing="linear"
                        >
                          Divyanshu
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="1200"
                          data-aos-duration="600"
                          data-aos-offset="0"
                          data-aos-easing="linear"
                        >
                          Kalola
                        </span>
                      </h1>
                      <h1 className="HeroTextMed">
                        <span
                          data-aos="fade-down"
                          data-aos-delay="1700"
                          data-aos-duration="600"
                          data-aos-offset="0"
                          data-aos-easing="linear"
                        >
                          Web
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="1800"
                          data-aos-duration="600"
                          data-aos-offset="0"
                        >
                          developer
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="1900"
                          data-aos-duration="600"
                          data-aos-offset="0"
                        >
                          by
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="2000"
                          data-aos-duration="600"
                          data-aos-offset="0"
                        >
                          skill
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="2100"
                          data-aos-duration="600"
                          data-aos-offset="0"
                        >
                          and
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="2200"
                          data-aos-duration="600"
                          data-aos-offset="0"
                        >
                          computer
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="2300"
                          data-aos-duration="600"
                          data-aos-offset="0"
                        >
                          engineer
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="2400"
                          data-aos-duration="600"
                          data-aos-offset="0"
                        >
                          by major,
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="2500"
                          data-aos-duration="600"
                          data-aos-offset="0"
                        >
                          born in
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="2600"
                          data-aos-duration="600"
                          data-aos-offset="0"
                        >
                          India,
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="2700"
                          data-aos-duration="600"
                          data-aos-offset="0"
                        >
                          Working in
                        </span>{" "}
                        <span
                          data-aos="fade-down"
                          data-aos-delay="2800"
                          data-aos-duration="600"
                          data-aos-offset="0"
                        >
                          Chicago.
                        </span>
                      </h1>
                      <div
                        className="row mt-5 d-flex justify-content-center"
                        data-aos="fade-down"
                        data-aos-delay="2800"
                        data-aos-duration="600"
                        data-aos-offset="0"
                      >
                        {/* Check prevHeight in section.js file line 26 */}
                        <div className="col-1 mx-3 position-relative sectionCount">
                          <button
                            className="heroNav"
                            onClick={() => {
                              window.scrollTo(0, windowHeight * 1.1);
                            }}
                          >
                            .01
                          </button>
                        </div>
                        <div className="col-1 mx-3 position-relative sectionCount">
                          <button
                            className="heroNav"
                            onClick={() => {
                              window.scrollTo(0, windowHeight * 5);
                            }}
                          >
                            .02
                          </button>
                        </div>
                        <div className="col-1 mx-3 position-relative sectionCount">
                          <button
                            className="heroNav"
                            onClick={() => {
                              window.scrollTo(0, windowHeight * 8.6);
                            }}
                          >
                            .03
                          </button>
                        </div>
                        <div className="col-1 mx-3 position-relative sectionCount">
                          <button
                            className="heroNav"
                            onClick={() => {
                              window.scrollTo(0, windowHeight * 12.23);
                            }}
                          >
                            .04
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* right */}
                    <div className="col-2 align-self-center ">
                      <div
                        className="row d-flex justify-content-start"
                        data-aos="fade-down"
                        data-aos-delay="1600"
                        data-aos-duration="600"
                        data-aos-offset="0"
                      >
                        <div className="col-sm-2 ">
                          <img src={notri} alt="" height={"20px"} />
                        </div>
                        <div className="col-sm-2">
                          {" "}
                          <img src={wave} alt="" height={"20px"} />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                {" "}
                <td className="align-bottom">
                  {" "}
                  {/* bottom */}
                  <div className="row align-end">
                    <div
                      className="col"
                      data-aos="fade-down"
                      data-aos-delay="1300"
                      data-aos-duration="400"
                      data-aos-offset="0"
                    >
                      <div className="row">
                        <div className="col arrow">
                          <BsArrowDown
                            onClick={() => {
                              scrollTo(0, 1582);
                            }}
                            style={{ fontSize: "30px" }}
                          ></BsArrowDown>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Hero;
