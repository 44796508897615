import React, { useState } from "react";

import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import TextField from "@mui/material/TextField";
import Bg from "./bg";
import Nav from "./nav";
import { Editor } from "@tinymce/tinymce-react";

import { useForm } from "react-hook-form";

import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase-config";
import { v4 } from "uuid";
import { useEffect } from "react";

import Switch from "@mui/material/Switch";

AOS.init();
function BlogEdit({ odj }) {
  const editorRef = React.useRef(null);
  const { register, getValues } = useForm({});
  const [id] = useState(v4());

  const [loading, setLoading] = useState(false);
  const [desc, setDesc] = useState(odj.desc);

  useEffect(() => {}, []);
  // async function writeData() {
  //   if (
  //     file === null ||
  //     getValues("desc") === undefined ||
  //     getValues("title") === undefined ||
  //     getValues("date") === undefined ||
  //     getValues("cat") === undefined ||
  //     getValues("featured") === undefined
  //   ) {
  //     alert("You forgot something.");
  //     return;
  //   }
  //   setLoading(true);

  //   const imageRef = ref(storage, `Blog/${id}`);

  //   await uploadBytes(imageRef, file).then((snapshot) => {
  //     getDownloadURL(snapshot.ref).then((url) => {
  //       console.log(url);
  //       addDoc(dataCollectionRef1, {
  //         title: getValues("title"),
  //         desc: desc,
  //         date: getValues("date"),
  //         img: url,
  //         cat: getValues("cat"),
  //         featured: getValues("featured"),
  //         blogID: id,
  //       });
  //     });
  //   });

  //   await setTimeout(function () {
  //     setLoading(false);
  //     window.location.reload(false);
  //   }, 5000);
  // }

  function dateConvert(date) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(date.seconds);

    var today = new Date(t);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = mm + "/" + dd + "/" + yyyy;
    return today;
  }

  async function update() {
    const projDoc = doc(db, "Blog", odj.id);
    alert(
      JSON.stringify({
        title: getValues("title"),
        desc: desc,
        date: getValues("date"),
        publish: getValues("publish"),
        cat: getValues("cat"),
        featured: getValues("featured"),
        blogID: id,
      })
    );

    setLoading(true);
    await updateDoc(projDoc, {
      title: getValues("title"),
      desc: desc,
      date: getValues("date"),
      publish: getValues("publish"),
      cat: getValues("cat"),
      featured: getValues("featured"),
    });
    await setTimeout(function () {
      setLoading(false);
      window.location.reload(false);
    }, 1000);
  }

  return (
    <>
      <Bg></Bg>
      <Nav color={"black"}></Nav>

      <div
        className="SectionBody"
        style={{
          backgroundColor: "rgba(255,255,255,1)",
        }}
      >
        <table
          style={{
            height: "90vh",
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td class="align-top">
                <h6>D</h6>
                <h6>K</h6>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td class="align-middle">
                <div className="row d-flex justify-content-center">
                  <div className="col-10 position-relative">
                    <div
                      className="row card-group d-flex justify-content-center"
                      style={{ fontFamily: "inherit" }}
                    >
                      <div className="col position-relative mx-3">
                        <div className="row mb-4 ">
                          <div className="col">
                            <TextField
                              id="outlined-basic"
                              label="Title"
                              variant="outlined"
                              defaultValue={odj.title}
                              {...register("title")}
                              inputProps={{
                                style: { fontFamily: "Share Tech Mono" },
                              }}
                              InputLabelProps={{
                                style: { fontFamily: "Share Tech Mono" },
                              }}
                            />
                          </div>
                          <div className="col">
                            <TextField
                              id="outlined-basic"
                              label="Date"
                              variant="outlined"
                              defaultValue={dateConvert(odj.date)}
                              {...register("date", {
                                valueAsDate: true,
                              })}
                              inputProps={{
                                style: { fontFamily: "Share Tech Mono" },
                              }}
                              InputLabelProps={{
                                style: { fontFamily: "Share Tech Mono" },
                              }}
                            />
                          </div>
                          <div className="col">
                            <TextField
                              id="outlined-basic"
                              label="Category"
                              variant="outlined"
                              defaultValue={odj.cat}
                              {...register("cat")}
                              inputProps={{
                                style: { fontFamily: "Share Tech Mono" },
                              }}
                              InputLabelProps={{
                                style: { fontFamily: "Share Tech Mono" },
                              }}
                            />
                          </div>
                          <div className="col">
                            <img
                              className="mx-3"
                              src={odj.img}
                              alt=""
                              height={"100px"}
                            />

                            {/* <input
                              type="file"
                              id="upload"
                              hidden
                              onChange={(event) => {
                                setFile(event.target.files[0]);
                              }}
                            />
                            <label
                              for="upload"
                              className={
                                file !== null
                                  ? "btn btn-success"
                                  : "btn btn-secondary"
                              }
                            >
                              {file !== null ? file.name : "Title Picture"}
                            </label> */}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <Editor
                              onInit={(evt, editor) =>
                                (editorRef.current = editor)
                              }
                              onChange={(e) => {
                                register("desc", {
                                  value: e.target.getContent(),
                                });
                                setDesc(e.target.getContent());
                              }}
                              initialValue={odj.desc}
                              init={{
                                height: 480,
                                max_height: 800,
                                menubar: true,
                                plugins: [
                                  "advlist",
                                  "autolink",
                                  "lists",
                                  "link",
                                  "image",
                                  "charmap",
                                  "print",
                                  "preview",
                                  "anchor",
                                  "searchreplace",
                                  "visualblocks",
                                  "code",
                                  "fullscreen",
                                  "insertdatetime",
                                  "autoresize",
                                  "media",
                                  "visualchars",
                                  "paste",
                                  "code",
                                  "help",
                                  "wordcount",
                                  "table",
                                  "template",
                                ],

                                toolbar:
                                  "blockquote bold italic underline forecolor backcolor | subscript superscript alignleft aligncenter " +
                                  "alignright alignjustify | bullist numlist outdent indent lineheight | " +
                                  "h1 h2 h3 h4 h5 h6 |fontsizeselect| removeformat table tableinsertdialog tablecellprops tableprops advtablerownumbering | fullscreen",
                                content_style:
                                  "body { font-family:Helvetica,Arial,sans-serif; font-size:15px }",
                              }}
                            />
                          </div>
                        </div>
                        <div className="row my-3">
                          <div className="col">
                            Featured:{" "}
                            <Switch {...register("featured")} defaultChecked />
                          </div>
                          <div className="col">
                            Publish: <Switch {...register("publish")} />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col">
                            {loading ? (
                              <button>Uploading your data ... </button>
                            ) : (
                              <button onClick={update}>Update</button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default BlogEdit;
