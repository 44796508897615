import React from "react";

import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Bg from "./bg";
import Nav from "./nav";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { sha256 } from "js-sha256";
import { useNavigate } from "react-router-dom";

AOS.init();
const Login = ({ passData }) => {
  const { register, getValues } = useForm({});
  const navigate = useNavigate();
  function submit() {
    if (
      getValues("email") === "divyanshukalola88@gmail.com" &&
      sha256(getValues("pass")) ===
        "f5bf6477e1480e4bfadc7db1917424cd2044503aec8041810341314cff415d55"
    ) {
      passData(true);
      navigate("/divyanshukalolaadmin");
    }
  }
  return (
    <>
      <Bg></Bg>
      <Nav color={"black"}></Nav>
      <div
        className="SectionBody"
        style={{ backgroundColor: "rgba(255,255,255,1)" }}
      >
        {" "}
        <table
          style={{
            height: "100vh",
            width: "100vw",
            color: "black",
            zIndex: "9999",
          }}
        >
          <tbody>
            <tr>
              <td class="align-middle">
                <div className="row justify-content-center">
                  <div className="col-7 position-relative">
                    {" "}
                    <div className="row my-3">
                      <div className="col">
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          {...register("email")}
                        />
                      </div>
                    </div>
                    <div className="row">
                      {" "}
                      <div className="col">
                        <TextField
                          id="outlined-basic"
                          label="f5bf6477e1480e4bfadc7db1917424cd2044503aec8041810341314cff415d55"
                          type="password"
                          variant="outlined"
                          fullWidth
                          {...register("pass")}
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col">
                        <Button variant="contained" onClick={submit}>
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Login;
