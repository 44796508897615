import React, { useState } from "react";

import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import TextField from "@mui/material/TextField";
import Bg from "./bg";
import Nav from "./nav";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";

import { addDoc, collection } from "firebase/firestore";
import { db } from "./../../firebase-config";
import { v4 } from "uuid";

AOS.init();
function Contact() {
  const { register, getValues } = useForm({});
  const [id] = useState(v4());
  const dataCollectionRef1 = collection(db, "ContactMe");
  const [loading, setLoading] = useState(false);

  async function sendEmail() {
    const emailForm = {
      to_name: `${getValues("fname")} ${getValues("lname")}`,
      app_name: `Name: ${getValues("fname")} ${getValues("lname")}`,
      app_email: `Email: ${getValues("email")}`,
      app_phone: `Phone: ${getValues("phone")}`,
      app_message: `Message: ${getValues("message")}`,
      to_email: "divyanshukalola88@gmail.com",
      sub_title: `Message from: ${getValues("fname")} ${getValues("lname")}`,
      app_id: id,
    };

    await emailjs
      .send(
        "service_cvets7y",
        "template_r2o35bl",
        emailForm,
        "aqzKs0f24gmAZAWOH"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  }
  async function writeData() {
    setLoading(true);

    await addDoc(dataCollectionRef1, {
      fname: getValues("fname"),
      lname: getValues("lname"),
      email: getValues("email"),
      phone: getValues("phone"),
      message: getValues("message"),
      userID: id,
    });

    await sendEmail();

    await setTimeout(function () {
      setLoading(false);
      window.location.reload(false);
    }, 100);
  }
  return (
    <>
      <Bg></Bg>
      <Nav active="CONTACT" style={{ color: "black" }}></Nav>
      <div
        className="SectionBody"
        style={{
          backgroundColor: "rgba(255,255,255,1)",
        }}
      >
        <table
          style={{
            height: "100vh",
            width: "100%",
            backgroundColor: "rgba(255,255,255,0.2)",
          }}
        >
          <tbody>
            <tr>
              <td class="align-top">
                <h6>D</h6>
                <h6>K</h6>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td class="align-middle">
                <div className="row d-flex justify-content-center">
                  <div className="col-7 position-relative">
                    <div className="row " style={{ fontFamily: "inherit" }}>
                      <div
                        className="col"
                        data-aos="zoom-in"
                        data-aos-delay="100"
                        data-aos-duration="400"
                        data-aos-offset="-1"
                      >
                        <p
                          style={{
                            textTransform: "uppercase",
                            letterSpacing: "3px",
                            fontSize: "40px",
                          }}
                        >
                          Contact Me
                        </p>
                      </div>
                    </div>
                    <hr />
                    <br />
                    <div className="row mt-3 d-flex justify-content-center">
                      <div className="col-12 mb-3 position-relative">
                        <TextField
                          id="outlined-basic"
                          label="First Name"
                          variant="outlined"
                          {...register("fname")}
                          inputProps={{
                            style: { fontFamily: "Share Tech Mono" },
                          }}
                          InputLabelProps={{
                            style: { fontFamily: "Share Tech Mono" },
                          }}
                        />
                      </div>
                      <div className="col-12 position-relative">
                        <TextField
                          id="outlined-basic"
                          label="Last Name"
                          variant="outlined"
                          {...register("lname")}
                          inputProps={{
                            style: { fontFamily: "Share Tech Mono" },
                          }}
                          InputLabelProps={{
                            style: { fontFamily: "Share Tech Mono" },
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-3 d-flex justify-content-center">
                      <div className="col-12 mb-3 position-relative">
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          {...register("email")}
                          inputProps={{
                            style: { fontFamily: "Share Tech Mono" },
                          }}
                          InputLabelProps={{
                            style: { fontFamily: "Share Tech Mono" },
                          }}
                        />
                      </div>
                      <div className="col-12 position-relative">
                        <TextField
                          id="outlined-basic"
                          label="Phone"
                          variant="outlined"
                          {...register("phone")}
                          inputProps={{
                            style: { fontFamily: "Share Tech Mono" },
                          }}
                          InputLabelProps={{
                            style: { fontFamily: "Share Tech Mono" },
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-3 d-flex justify-content-center">
                      <div className="col-12 position-relative">
                        <TextField
                          id="outlined-multiline-static"
                          label="Type your message here ..."
                          {...register("message")}
                          multiline
                          fullWidth
                          inputProps={{
                            style: { fontFamily: "Share Tech Mono" },
                          }}
                          InputLabelProps={{
                            style: { fontFamily: "Share Tech Mono" },
                          }}
                        />
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col">
                        {loading ? (
                          <button disabled>Saving your response ...</button>
                        ) : (
                          <button onClick={writeData}>Submit</button>
                        )}
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Contact;
