import React, { useState } from "react";

import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";

import Bg from "./bg";
import Nav from "./nav";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

AOS.init();
function Blog({ blog }) {
  const navigate = useNavigate();
  const [state, setState] = useState("featured");

  function dateConvert(date) {
    var t = new Date(1970, 0, 2); // India 2
    t.setSeconds(date.seconds);
    const d = new Date(t);
    let text = d.toDateString();
    return text;
  }

  function getDescription(text) {
    const str = text;

    let doc = new DOMParser().parseFromString(str, "text/html");

    return doc.querySelector("p").textContent.length > 200
      ? doc.querySelector("p").textContent.substring(0, 200) + " ..."
      : doc.querySelector("p").textContent;
  }

  function organiseData(array, size, cat) {
    var perChunk = size; // items per chunk
    var inputArray;
    if (cat === "all") {
      inputArray = array;
    } else if (cat === "featured") {
      inputArray = array.filter((e) => e.featured === true);
    } else {
      inputArray = array.filter((e) => e.cat === cat);
    }

    var result = inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    return result;
  }

  function getCat() {
    const res = Object.values(
      blog.reduce((a, { cat }) => {
        a[cat] = { cat };
        return a;
      }, {})
    );

    console.log(res);
    return res;
  }

  return (
    <>
      <Bg></Bg>
      <Nav active="BLOG" color={"black"}></Nav>

      <div
        className="SectionBody"
        style={{
          backgroundColor: "rgba(255,255,255,1)",
        }}
      >
        <table
          style={{
            width: "100%",
            backgroundColor: "rgba(255,255,255,0.2)",
          }}
        >
          <tbody>
            <tr>
              <td class="align-top">
                <h6>D</h6>
                <h6>K</h6>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td class="align-middle">
                <div className="row d-flex justify-content-center mb-5">
                  <div className="col-9 position-relative">
                    <div className="row " style={{ fontFamily: "inherit" }}>
                      <div
                        className="col"
                        data-aos="zoom-in"
                        data-aos-delay="100"
                        data-aos-duration="400"
                        data-aos-offset="-1"
                      >
                        <p
                          style={{
                            textTransform: "uppercase",
                            letterSpacing: "3px",
                            fontSize: "40px",
                          }}
                        >
                          My Blog
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row justify-content-center">
                      <div
                        className={
                          state === "featured"
                            ? "col-2 mt-2 position-relative active"
                            : "col-2 mt-2 position-relative"
                        }
                      >
                        <button
                          className="plainbut"
                          onClick={() => {
                            setState("featured");
                          }}
                        >
                          Featured
                        </button>
                      </div>
                      <div
                        className={
                          state === "all"
                            ? "col-2 mt-2 position-relative active"
                            : "col-2 mt-2 position-relative"
                        }
                      >
                        <button
                          className="plainbut"
                          onClick={() => {
                            setState("all");
                          }}
                        >
                          All
                        </button>
                      </div>
                      {getCat().map((cat) => {
                        return (
                          <div
                            className={
                              state === cat.cat
                                ? "col-2 mt-2 position-relative active"
                                : "col-2 mt-2 position-relative"
                            }
                          >
                            <button
                              className="plainbut"
                              onClick={() => {
                                setState(cat.cat);
                              }}
                            >
                              {cat.cat}
                            </button>
                          </div>
                        );
                      })}
                    </div>
                    <hr />

                    {organiseData(blog, 2, state).map((obj1) => {
                      return (
                        <div className="row mt-4">
                          {obj1.map((obj) => {
                            return (
                              <>
                                <div
                                  className="col-6"
                                  style={{ textAlign: "left" }}
                                >
                                  <Card sx={{ maxWidth: "inherit" }}>
                                    {obj.img ? (
                                      <CardMedia
                                        component="img"
                                        alt="img"
                                        height="200"
                                        image={obj.img}
                                      />
                                    ) : null}

                                    <CardContent>
                                      <Typography
                                        variant="caption"
                                        display="block"
                                      >
                                        {obj.cat}
                                      </Typography>
                                      <Typography
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                      >
                                        {obj.title}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="text.secondary"
                                      >
                                        {getDescription(obj.desc)}
                                      </Typography>
                                      <br />
                                      <div className="row">
                                        <div className="col">
                                          <Typography
                                            variant="caption"
                                            display="block"
                                            gutterBottom
                                          >
                                            {dateConvert(obj.date)}
                                          </Typography>
                                        </div>
                                        <div
                                          className="col"
                                          style={{ textAlign: "right" }}
                                        >
                                          <Button
                                            size="small"
                                            onClick={() => {
                                              navigate(`/blog-${obj.id}`);
                                            }}
                                          >
                                            Learn More
                                          </Button>
                                        </div>
                                      </div>
                                    </CardContent>
                                    <CardActions></CardActions>
                                  </Card>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Blog;
