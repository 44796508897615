import React from "react";

import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();
function Footer() {
  return (
    <div className="SectionBody">
      <table
        style={{
          height: "50vh",
          width: "100%",
          backgroundColor: "rgba(48,48,48)",
          color: "white",
        }}
      >
        <tbody>
          <tr>
            <td class="align-middle">
              <div className="row d-flex justify-content-center">
                <div className="col-7 position-relative">
                  <div className="row ">
                    <div
                      className="col"
                      data-aos="zoom-in"
                      data-aos-delay="100"
                      data-aos-duration="400"
                      data-aos-offset="-1"
                    >
                      <p
                        style={{
                          textTransform: "uppercase",
                          letterSpacing: "3px",
                        }}
                      >
                        d8ced8315e96f15b2ed3456497ece2a2324bcf180fe1ffb522b489f43c42c037
                      </p>
                    </div>
                  </div>
                  <div
                    className="row border-top d-flex justify-content-center"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                    data-aos-duration="400"
                    data-aos-offset="-1"
                    style={{ lineHeight: "10px" }}
                  >
                    <div className="col-2 mt-2 position-relative">
                      <a href="/DivyanshuKalola">
                        <p
                          data-aos="fade-up"
                          data-aos-delay="100"
                          data-aos-duration="400"
                          data-aos-offset="0"
                        >
                          D
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="150"
                          data-aos-duration="400"
                          data-aos-offset="0"
                        >
                          K
                        </p>
                      </a>
                    </div>
                    <div className="col-2 mt-2 position-relative">
                      <a href="/">
                        <p
                          data-aos="fade-up"
                          data-aos-delay="100"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          W
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="150"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          O
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="200"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          R
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="250"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          K
                        </p>
                      </a>
                    </div>
                    <div className="col-2 mt-2 position-relative">
                      <a
                        href="https://firebasestorage.googleapis.com/v0/b/kaizeninfracon-15b66.appspot.com/o/d8ced8315e96f15b2ed3456497ece2a2324bcf180fe1ffb522b489f43c42c037%2FDivyanshu%20dilipbhaiKalola_resume2.pdf?alt=media&token=95bcc00e-ec6a-47fa-82fb-ecbdae560521"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p
                          data-aos="fade-up"
                          data-aos-delay="100"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          R
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="150"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          É
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="200"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          S
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="250"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          U
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="300"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          M
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="350"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          É
                        </p>
                      </a>
                    </div>
                    <div className="col-2 mt-2 position-relative">
                      <a href="/Blog">
                        <p
                          data-aos="fade-up"
                          data-aos-delay="100"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          B
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="150"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          L
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="200"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          O
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="250"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          G
                        </p>
                      </a>
                    </div>
                    <div className="col-2 mt-2 position-relative">
                      <a href="/Contact">
                        <p
                          data-aos="fade-up"
                          data-aos-delay="100"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          C
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="150"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          O
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="200"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          N
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="250"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          T
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="300"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          A
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="350"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          C
                        </p>
                        <p
                          data-aos="fade-up"
                          data-aos-delay="400"
                          data-aos-duration="400"
                          data-aos-offset="-1"
                        >
                          T
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Footer;
