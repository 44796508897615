import React, { useState } from "react";
import "./App.css";
import "./static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import useWindowDimensions from "./components/useWindowDimensions";

// componenets desktop View
import Wrok from "./components/DesktopView/Work";
import DK from "./components/DesktopView/DK";

import Contact from "./components/DesktopView/contact";
import Footer from "./components/DesktopView/footer";
import Blog from "./components/DesktopView/blog";
import BlogView from "./components/DesktopView/blogView";
import RecordsView from "./components/DesktopView/records";

import Admin from "./components/DesktopView/admin";
import Login from "./components/DesktopView/login";

// componenets mobile View
import WorkMobile from "./components/MobileView/Work";
// import DKMobile from "./components/MobileView/DK";

// import ContactMobile from "./components/MobileView/contact";
import FooterMobile from "./components/MobileView/footer";
// import BlogMobile from "./components/MobileView/blog";
// import BlogViewMobile from "./components/MobileView/blogView";

// import AdminMobile from "./components/MobileView/admin";
// import LoginMobile from "./components/MobileView/login";

import readData from "./components/functoins/DB";

import { useEffect } from "react";

import { useCookies } from "react-cookie";
AOS.init();

function App() {
  const [cookies, setCookie] = useCookies(["user"]);

  const [blog, setblog] = useState([]);
  const [authenticated, setauthenticated] = useState(false);
  const { height, width } = useWindowDimensions();
  console.log(height);

  useEffect(() => {
    const getData = async () => {
      readData("Blog").then((e) => {
        setblog(e.filter((obj) => obj.publish));
      });
    };
    getData();
  }, []);

  const passData = (data) => {
    var tomorrow = new Date();

    tomorrow.setHours(tomorrow.getHours() + 3);

    if (cookies.Auth) {
      return;
    } else {
      setauthenticated(data);
      setCookie("Auth", true, { expires: tomorrow });
    }
  };
  return (
    <div className="App">
      <BrowserRouter>
        {width < 500 ? (
          // Mobile Screen size
          <Routes>
            <Route path="*" element={<WorkMobile></WorkMobile>} status={404} />
            <Route path="/" element={<WorkMobile></WorkMobile>} />
            <Route
              path="/DivyanshuKalola"
              element={<WorkMobile></WorkMobile>}
            />
            <Route path="/Contact" element={<Contact></Contact>} />
            <Route path="/Records" element={<RecordsView></RecordsView>} />
            <Route path="/Blog" element={<WorkMobile></WorkMobile>} />
            <Route
              path="/Login"
              element={<Login passData={passData}></Login>}
            />

            <Route
              path="/divyanshukalolaadmin"
              element={
                cookies.Auth || authenticated ? (
                  <Admin blog={blog}></Admin>
                ) : (
                  <Login passData={passData}></Login>
                )
              }
            />

            {blog.map((obj) => {
              return (
                <Route
                  path={`/blog-${obj.id}`}
                  element={<BlogView obj={obj}></BlogView>}
                />
              );
            })}
          </Routes>
        ) : (
          // Normal Screen size
          <Routes>
            <Route path="*" element={<Wrok></Wrok>} status={404} />
            <Route path="/" element={<Wrok></Wrok>} />
            <Route path="/DivyanshuKalola" element={<DK></DK>} />
            <Route path="/Contact" element={<Contact></Contact>} />
            <Route path="/Records" element={<RecordsView></RecordsView>} />
            <Route path="/Blog" element={<Blog blog={blog}></Blog>} />
            <Route
              path="/Login"
              element={<Login passData={passData}></Login>}
            />

            <Route
              path="/divyanshukalolaadmin"
              element={
                cookies.Auth || authenticated ? (
                  <Admin blog={blog}></Admin>
                ) : (
                  <Login passData={passData}></Login>
                )
              }
            />

            {blog.map((obj) => {
              return (
                <Route
                  path={`/blog-${obj.id}`}
                  element={<BlogView obj={obj}></BlogView>}
                />
              );
            })}
          </Routes>
        )}
      </BrowserRouter>
      {width < 900 ? <FooterMobile></FooterMobile> : <Footer></Footer>}
    </div>
  );
}

export default App;
