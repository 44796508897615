import React from "react";

import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

AOS.init();
function Nav({ color, active }) {
  const navigate = useNavigate();

  return (
    <div className="row navbut" style={{ color: color }}>
      <div
        className="col-1 mx-3 navText text-right  my-4"
        data-aos="fade-down"
        data-aos-delay="2500"
        data-aos-duration="400"
        data-aos-offset="0"
      >
        <div className="row">
          <div className="col li">
            <button
              className="plainbut"
              style={{
                borderBottom: active === "DK" ? "1px solid white" : "none",
              }}
              onClick={() => {
                navigate("/DivyanshuKalola");
              }}
            >
              DK
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col li">
            <button
              className="plainbut"
              style={{
                borderBottom: active === "WORK" ? "1px solid white" : "none",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              WORK
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col li">
            <button
              className="plainbut"
              style={{
                borderBottom: active === "BLOG" ? "1px solid black" : "none",
              }}
              onClick={() => {
                navigate("/Blog");
              }}
            >
              BLOG
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col li">
            <button
              className="plainbut"
              style={{
                borderBottom: active === "RESUME" ? "1px solid white" : "none",
              }}
              onClick={() => {
                // navigate("/Resume");
                window.open(
                  "https://firebasestorage.googleapis.com/v0/b/kaizeninfracon-15b66.appspot.com/o/d8ced8315e96f15b2ed3456497ece2a2324bcf180fe1ffb522b489f43c42c037%2FDivyanshu%20dilipbhaiKalola_resume2.pdf?alt=media&token=95bcc00e-ec6a-47fa-82fb-ecbdae560521",
                  "_blank"
                );
              }}
            >
              RÉSUMÉ
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col li">
            <button
              className="plainbut"
              style={{
                borderBottom: active === "CONTACT" ? "1px solid black" : "none",
              }}
              onClick={() => {
                navigate("/Contact");
              }}
            >
              CONTACT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;
