import React, { useState, useEffect } from "react";

import { BsArrowDown } from "react-icons/bs";

import { BsAsterisk } from "react-icons/bs";

import { BsCircleHalf } from "react-icons/bs";

import { BsCommand } from "react-icons/bs";

import { BsRainbow } from "react-icons/bs";

import Woop from "./../../static/sounds/woop.wav";
function Hero() {
  const ref2 = React.useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    ref2.current.style.backgroundColor = `rgba(48, 48, 48, ${
      0.3 + (position / 100) * 0.9
    })`;
  };

  // function clickSound() {
  //   const audio = new Audio(Click);
  //   audio.play();
  // }
  async function woopSound() {
    const audio = new Audio(Woop);
    audio.play();
  }

  async function scrollTo(x, y) {
    woopSound();
    window.scrollTo(x, y);
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    ref2.current.style.backgroundColor = `rgba(48, 48, 48, 0.3)`;
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log(scrollPosition);

  return (
    <div className="row Hero " ref={ref2}>
      <div className="col">
        <div className="sticky-top">
          <table style={{ height: "100vh" }}>
            <tbody>
              <tr>
                <td className="align-top">
                  {/* top */}
                  <div className="row">
                    <div className="col">
                      <h6>D</h6>
                      <h6>K</h6>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>
                  {/* middle */}
                  <div
                    className="row justify-content-end my-4"
                    data-aos="fade-down"
                    data-aos-delay="1400"
                    data-aos-duration="600"
                    data-aos-offset="0"
                    data-aos-easing="linear"
                  >
                    <div className="col-sm-2 ">
                      <BsAsterisk style={{ fontSize: "20px" }}></BsAsterisk>
                    </div>
                    <div className="col-sm-2">
                      <BsCircleHalf style={{ fontSize: "20px" }}></BsCircleHalf>
                    </div>
                  </div>
                  <div className="row">
                    {/* left */}

                    {/* center */}
                    <div className="col-sm align-self-center">
                      <div
                        className="row mt-5 p-3"
                        style={{ textAlign: "left" }}
                      >
                        <div
                          className="col  position-relative sectionCount"
                          style={{ fontWeight: "600" }}
                          data-aos="fade-down"
                          data-aos-delay="1400"
                          data-aos-duration="600"
                          data-aos-offset="0"
                          data-aos-easing="linear"
                        >
                          <p>Hello Visitor,</p>
                          <p>
                            This page is not ment for Mobile users. Please use
                            PC to view this page.
                          </p>
                          <p>Best,</p>
                          <p>Divyanshu Kalola</p>
                        </div>
                      </div>
                    </div>
                    {/* right */}
                  </div>
                  <div
                    className="row d-flex justify-content-start my-3"
                    data-aos="fade-down"
                    data-aos-delay="1600"
                    data-aos-duration="600"
                    data-aos-offset="0"
                  >
                    <div className="col-sm-2 ">
                      <BsCommand style={{ fontSize: "20px" }}></BsCommand>
                    </div>
                    <div className="col-sm-2">
                      {" "}
                      <BsRainbow style={{ fontSize: "20px" }}></BsRainbow>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                {" "}
                <td className="align-bottom">
                  {" "}
                  {/* bottom */}
                  <div className="row align-end">
                    <div
                      className="col"
                      data-aos="fade-down"
                      data-aos-delay="1300"
                      data-aos-duration="400"
                      data-aos-offset="0"
                    >
                      <div className="row">
                        <div className="col arrow">
                          <BsArrowDown
                            onClick={() => {
                              scrollTo(0, 1582);
                            }}
                            style={{ fontSize: "30px" }}
                          ></BsArrowDown>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Hero;
