import React, { useEffect, useState } from "react";

import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
// components
import Hero from "./hero";

import Section from "./section";

import Nav from "./nav";
import Bg from "./bg";

import ALIM1 from "./../../static/img/Alchemeo/AlchemeoStats.png";
import ALIM2 from "./../../static/img/Alchemeo/AlchemeoAddProd.png";
import ALIM3 from "./../../static/img/Alchemeo/AlchemeoDash.png";
import ALIM4 from "./../../static/img/Alchemeo/AlchemeoEditProd.png";
import ALIM5 from "./../../static/img/Alchemeo/AlchemeoInventory.png";
import ALIM6 from "./../../static/img/Alchemeo/AlchemeoInvoice.png";
import ALIM7 from "./../../static/img/Alchemeo/AlchemeoProfile.png";
import ALIM8 from "./../../static/img/Alchemeo/AlchemeoInvoiceStats.png";

import KZ1 from "./../../static/img/Kaizen/Screenshot 2023-09-17 at 1.07.08 PM.png";
import KZ2 from "./../../static/img/Kaizen/Screenshot 2023-09-17 at 1.07.20 PM.png";
import KZ3 from "./../../static/img/Kaizen/Screenshot 2023-09-17 at 1.07.33 PM.png";
import KZ4 from "./../../static/img/Kaizen/Screenshot 2023-09-17 at 1.07.58 PM.png";
import KZ5 from "./../../static/img/Kaizen/Screenshot 2023-09-17 at 1.09.02 PM.png";

import ATM1 from "./../../static/img/Automation/Automation.jpeg";
import ATM2 from "./../../static/img/Automation/Dashboard.png";
import ATM3 from "./../../static/img/Automation/PCB.png";
import ATM4 from "./../../static/img/Automation/Sample.jpeg";
import ATM5 from "./../../static/img/Automation/Dashboard-Phone.png";
import ATM6 from "./../../static/img/Automation/Areas.png";
import ATM7 from "./../../static/img/Automation/Automation.png";
AOS.init();
function Work() {
  // const [orient, setOrient] = useState(0);
  const [view, setView] = useState(false);
  const [navview] = useState(false);
  const [color, setColor] = useState("white");

  function handleScroll() {
    const position = window.scrollY;
    const windowHeight = window.innerHeight;

    if (position >= windowHeight * 2.6 && position < windowHeight * 4.9) {
      setColor("black");
    } else if (
      position >= windowHeight * 4.9 &&
      position < windowHeight * 6.5
    ) {
      setColor("white");
    } else if (
      position >= windowHeight * 6.5 &&
      position < windowHeight * 8.8
    ) {
      setColor("black");
    } else if (
      position >= windowHeight * 8.8 &&
      position < windowHeight * 10.4
    ) {
      setColor("white");
    } else if (
      position >= windowHeight * 10.4 &&
      position < windowHeight * 13.0
    ) {
      setColor("black");
    } else if (
      position >= windowHeight * 12.7 &&
      position < windowHeight * 14.4
    ) {
      setColor("white");
    } else if (
      position >= windowHeight * 14.4 &&
      position < windowHeight * 16.8
    ) {
      setColor("black");
    } else {
      setColor("white");
    }
  }

  useEffect(() => {
    const onPageLoad = () => {
      setView(true);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      // window.scrollTo(0, 0);
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => {
        window.removeEventListener("load", onPageLoad);
        window.removeEventListener("scroll", handleScroll);
      };
    }
  });

  const section0 = {
    title_name: "GANGATECHNOCAST",
    title_position: "Position: Co-Founder and Full Stack Web Developer",
    title_role: "Role: Create a company portfolio website.",
    title_tech:
      "Technologies: The website was built using Express JS framework, Material UI and Firebase for data management and hosting.",
    skills: [
      { name: "JavaScript", level: 97 },
      { name: "Firebase", level: 90 },
      { name: "Material UI", level: 80 },
      { name: "Bootstrap", level: 80 },
    ],
    section_title: "The art of Casting",
    section_descleft:
      "Ganga Technocast is an investment casting company. Being the Co-Founder of the company and a technology enthusiast. I started building a website to showcase the company's capabilities in 2023.",
    section_descright:
      "The website is built using light weight framework Express JS and using Material UI and Bootstrap for structuring UI components. I have also used Firebase for no-SQL database, hosting services and authentication service.",
    slides: false,
    slide_list: [],
    url: "https://gangatechnocast-2gm5.onrender.com/",
    year: "2023",
    period: "3 weeks",
    notes: "",
  };

  const section1 = {
    title_name: "KAIZENINFRACON",
    title_position: "Position: Full Stack Web Developer",
    title_role: "Role: Create a showcase website for a building company.",
    title_tech:
      "Technologies: React serves as the project's front end, and Firebase manages the backend operations. The UI is the result of the joint work of Bootstrap and Material UI.",
    skills: [
      { name: "JavaScript", level: 97 },
      { name: "Firebase", level: 80 },
      { name: "Material UI", level: 60 },
      { name: "Bootstrap", level: 80 },
    ],
    section_title: "The art of Architecture",
    section_descleft:
      "A construction company in India was searching for someone to create a website for them that would help them showcase their projects and inform their clients of what they do. They also wanted the website to be constructed in a way that would make it simple for them to update the data using built-in pages.",
    section_descright:
      "React and Firebase are both used in the development of the project. where Bootstrap and Material UI are in charge of the visuals. Using github, the hosting of the application is automated. When the repository is updated, the live version is also updated.",
    slides: true,
    slide_list: [
      {
        img: KZ1,
        cap: "Welcome Page",
      },
      {
        img: KZ2,
        cap: "Home Page showing our biggest prize",
      },
      {
        img: KZ3,
        cap: "Home Page showing the current projects",
      },
      {
        img: KZ4,
        cap: "About US Page",
      },
      {
        img: KZ5,
        cap: "News Page",
      },
    ],
    url: "",
    year: "2022",
    period: "2 Months",
    notes: "",
  };

  const section2 = {
    title_name: "ALCHEMEO",
    title_position: "Position: Full Stack Web Developer",
    title_role:
      "Role: Create an application for a small business invoicing and inventory.",
    title_tech:
      "Technologies: Django served as the project's front end, and the database was managed by MYSQL. The UI is the result of Bootstrap.",
    skills: [
      { name: "Python", level: 98 },
      { name: "MySql", level: 60 },
      { name: "Material UI", level: 70 },
      { name: "Bootstrap", level: 80 },
    ],
    section_title: "The art of invoicing",
    section_descleft:
      "I spent time developing a web application to control the inventory for my father's business, Modi Care. The software aids small-business sellers in creating invoices and controlling their inventories. The tool also offers crucial data that enables retailers to stock up their inventory in accordance with sales.",
    section_descright:
      "The application is backedup with Django which handles the frontend and backend functionality. The data is stored using MySql. The visuals were created by using Bootstrap. It took me aruond 5 months to finish the project where the user can create invoices and inventory, share inventory and share invoices.",
    slides: true,
    slide_list: [
      {
        img: ALIM1,
        cap: "Dashboard Stats: Display Stats of product and compares the number of product sold with each other.",
      },
      {
        img: ALIM2,
        cap: "Inventory: Enables the users to register a new product in their inventory.",
      },
      {
        img: ALIM3,
        cap: "Dashboard: Shows essential information such as number of items listed, number of users using your inventory, number of invoices in the current year and total sales of the current year.",
      },
      {
        img: ALIM4,
        cap: "Inventory: Enables edit and delete of the product listed in inventory.",
      },
      {
        img: ALIM5,
        cap: "Inventory: Lists all the product in the inventory and allows the user to edit the product.",
      },
      {
        img: ALIM6,
        cap: "Invoices: Enables user to create the invoice.",
      },
      {
        img: ALIM7,
        cap: "Profile: Display of user profile and allows the user to edit the information or join new inventory.",
      },
      {
        img: ALIM8,
        cap: "Invoices: Display invoices that were created and allow the user to view, delete or print the invoices.",
      },
    ],
    url: "",
    year: "2021",
    period: "5 Months",
    notes: "",
  };
  const section3 = {
    title_name: "AUTOMATION",
    title_position: "Position: Computer Engineer",
    title_role:
      "Role: Developed a system of automation that sits on top of the electrical infrastructure already in place. The device is inexpensive and employs ML models to advise the user on when to turn equipment on or off in order to save energy.",
    title_tech:
      "Technologies: The ESP32 and ESP8266 serve as the project's brains. It then uses the MQTT protocol to communicate with the local server. Your personal computer or the Raspberry Pi that we are utilizing in our test setup can function as the server. The server gathers the data and uses the Google API to deliver the necessary data to the ML engines for further processing.",
    skills: [
      { name: "C Language", level: 98 },
      { name: "React.JS", level: 80 },
      { name: "Node Red", level: 90 },
      { name: "MQTT", level: 80 },
      { name: "MySQL", level: 70 },
    ],
    section_title: "Smart Electrical Grid",
    section_descleft:
      "This project aims to lower the amount of electricity used by both commercial and residential buildings. This device tracks your electrical usage and also gains knowledge from it. The hardware and software for the system are being built from the ground up. We are developing an interactive dashboard that will display statistics and notify users when particular electrical appliances should be turned off for desktop and mobile users.",
    section_descright:
      "The brains of the system on which I am working are the ESP32 and ESP8266, which are hidden behind the switchboards. These modules will then communicate with a central server via wifi to broadcast the data they have gathered, such as power usage. The server will also govern switching and, if AL mode is selected, will employ ML engines from Google to make effective decisions. The cloudflare API allows access to the system from any location in the world.",
    slides: true,
    slide_list: [
      {
        img: ATM1,
        cap: "Automation Flow.",
      },
      {
        img: ATM3,
        cap: "First Prototype of PCB design.",
      },
      {
        img: ATM4,
        cap: "First Prototype of Hardware",
      },
      {
        img: ATM2,
        cap: "Automation Dashboard for Desktop Users",
      },
      {
        img: ATM5,
        cap: "Automation Dashboard for Mobile Users",
      },
      {
        img: ATM6,
        cap: "Dashboard showing aread available in your home.",
      },
      {
        img: ATM7,
        cap: "Dashboard to set the customized time for your lights.",
      },
    ],
    url: "",
    year: "2022 - Present",
    period: "8 Months",
    notes:
      "The first prototype is running at my house in India and its been 1 year and 6 months since it was installed. I have not seen any issues or maintainances.",
  };

  return (
    <div className="App">
      {view ? (
        <>
          <Nav active="WORK" color={color}></Nav>
          <Bg></Bg>

          <Hero navView={navview}></Hero>

          <Section order={0} data={section0} end={false}></Section>
          <Section order={1} data={section1} end={false}></Section>
          <Section order={2} data={section2} end={false}></Section>
          <Section order={3} data={section3} end={true}></Section>
        </>
      ) : null}
    </div>
  );
}

export default Work;
