import React, { useEffect, useState } from "react";

import LinearProgress from "@mui/material/LinearProgress";
import { BsArrowDown } from "react-icons/bs";
import { BsFillCaretDownFill } from "react-icons/bs";
import { BsArrowLeftShort } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";

function Section({ order, data, end }) {
  const title = React.useRef();
  const title_name = React.useRef();
  const title_body = React.useRef();
  const section_number = React.useRef();

  const refSlide = React.useRef();
  const refSlideCap = React.useRef();

  const [slideCount, setSlideCount] = useState(0);

  const first = React.useRef();

  useEffect(() => {
    console.log("OffsetHeight: ", first.current.offsetTop);
    const handleScroll = () => {
      const position = window.scrollY;

      // Add values depending on the number of the sections.

      let startPosition = first.current.offsetTop - 10;

      if (position >= startPosition * 1.005) {
        let rate = position - startPosition * 1.009;
        section_number.current.style.color = `rgba(255, 255, 255, ${
          1 - rate * 0.1
        })`;
      }

      //  Start showing the title
      if (position >= startPosition * 1.0) {
        let rate = position - startPosition * 1.0;
        let fadeRate = 1 - rate * 0.008;

        if (fadeRate >= 0.2) {
          title.current.style.backgroundColor = `rgba(48, 48, 48, ${fadeRate})`;
          title_name.current.style.color = `rgba(255, 255, 255, ${
            rate * 0.005
          })`;
          title_body.current.style.color = `rgba(255, 255, 255, ${
            rate * 0.005
          })`;
        } else {
          title.current.style.backgroundColor = `rgba(48, 48, 48, 0.2)`;
          title_name.current.style.color = `rgba(255, 255, 255, ${1})`;
          title_body.current.style.color = `rgba(255, 255, 255, ${1})`;
        }
      } else {
        title.current.style.backgroundColor = `rgba(48, 48, 48, 1)`;
        title_name.current.style.color = `rgba(255, 255, 255, ${0})`;
        title_body.current.style.color = `rgba(255, 255, 255, ${0})`;
      }
    };
    title.current.style.backgroundColor = `rgba(48, 48, 48, 1)`;

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [order, section_number, title, title_name, title_body]);

  function ChangeSlideRight() {
    if (slideCount + 1 > data.slide_list.length - 1) {
      setSlideCount(0);
    } else {
      setSlideCount(slideCount + 1);
    }
    refSlide.current.src = data.slide_list[slideCount].img;
    refSlideCap.current.innerText = data.slide_list[slideCount].cap;
  }
  function ChangeSlideLeft() {
    console.log(slideCount);
    if (slideCount - 1 <= 0) {
      setSlideCount(data.slide_list.length - 1);
    } else {
      setSlideCount(slideCount - 1);
    }
    refSlide.current.src = data.slide_list[slideCount].img;
    refSlideCap.current.innerText = data.slide_list[slideCount].cap;
  }

  return (
    <>
      <div className="row" ref={first}>
        <div className="col">
          <div
            className="SectionBody"
            style={{ color: "white", width: "100vw", height: "160vh" }}
          >
            <div className="sticky-top" ref={title}>
              <div className="row">
                <div className="col">
                  <h6>D</h6>
                  <h6>K</h6>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-2">
                  <table style={{ height: "100vh" }}>
                    <tbody>
                      <tr>
                        <td class="align-middle">
                          <span
                            style={{ fontSize: "160px" }}
                            ref={section_number}
                          >
                            .0{order + 1}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-7 align-self-end SectionWork"
                  ref={title_name}
                >
                  <p>{data.title_name}</p>
                </div>
                <div
                  className="col-5 align-self-end SectionWorkDes"
                  ref={title_body}
                >
                  <p
                    data-aos="fade-left"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                  >
                    {data.title_position}
                  </p>
                  <p
                    data-aos="fade-left"
                    data-aos-delay="700"
                    data-aos-duration="1000"
                  >
                    {data.title_role}
                  </p>
                  <p
                    data-aos="fade-left"
                    data-aos-delay="900"
                    data-aos-duration="1000"
                  >
                    {data.title_tech}
                  </p>
                  <p
                    data-aos="fade-left"
                    data-aos-delay="1100"
                    data-aos-duration="1000"
                  >
                    Skills:{" "}
                  </p>

                  {data.skills.map((item) => (
                    <p
                      data-aos="fade-left"
                      data-aos-delay="1100"
                      data-aos-duration="1000"
                    >
                      {item.name}
                      <LinearProgress
                        variant="determinate"
                        color="inherit"
                        value={item.level}
                      />
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div
            className="SectionBody"
            style={{
              height: "90vh",
              backgroundColor: "rgb(255, 255, 255)",
              width: "100vw",
            }}
          >
            <div className="sticky-top">
              <div style={{ backgroundColor: "rgb(255, 255, 255)" }}></div>
              <div className="row mb-5">
                <div className="col my-3 mb-5">
                  <h6>SECTION .0{order + 1}</h6>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col mt-5">
                  <p
                    className="title"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out-cubic"
                  >
                    {data.section_title}
                  </p>
                </div>
              </div>
              <div className="row mt-5 d-flex justify-content-center">
                <div className="col-3 mx-2 position-relative">
                  <p
                    style={{
                      width: "25vw",
                      textAlign: "justify",
                    }}
                    data-aos="fade-right"
                    data-aos-delay="100"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out-cubic"
                  >
                    {data.section_descleft}
                  </p>
                </div>
                <div className="col-3 mx-2 position-relative">
                  <p
                    style={{ width: "25vw", textAlign: "justify" }}
                    data-aos="fade-left"
                    data-aos-delay="100"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out-cubic"
                  >
                    {data.section_descright}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="SectionBody"
            style={{ backgroundColor: "rgb(255, 255, 255)", width: "100vw" }}
          >
            <div>
              <div className="row">
                <div className="col my-5">
                  <BsFillCaretDownFill
                    style={{ fontSize: "30px" }}
                  ></BsFillCaretDownFill>
                </div>
              </div>
              <div className="row ">
                <div className="col  mb-5">
                  {data.slides ? (
                    <div className="row d-flex justify-content-center">
                      <div className="col-1">
                        <hr style={{ position: "relative", top: "45%" }} />
                      </div>
                      <div className="col ">
                        <img
                          src={data.slide_list[0].img}
                          height="600px"
                          alt=""
                          ref={refSlide}
                          className="slide"
                        />
                        <div className="row d-flex justify-content-center">
                          <div className="col">
                            <BsArrowLeftShort
                              onClick={ChangeSlideLeft}
                              style={{ fontSize: "30px", cursor: "pointer" }}
                            ></BsArrowLeftShort>
                          </div>
                          <div className="col-6 ">
                            <p className="mx-5" ref={refSlideCap}>
                              {data.slide_list[0].cap}
                            </p>
                          </div>
                          <div className="col">
                            {" "}
                            <BsArrowRightShort
                              onClick={ChangeSlideRight}
                              style={{ fontSize: "30px", cursor: "pointer" }}
                            ></BsArrowRightShort>
                          </div>
                        </div>
                      </div>
                      <div className="col-1">
                        <hr style={{ position: "relative", top: "45%" }} />
                      </div>
                    </div>
                  ) : (
                    // <iframe
                    //   src={data.url}
                    //   title="description"
                    //   width={"1200px"}
                    //   height={"700px"}
                    //   className="frame-container shadow-lg"
                    // ></iframe>
                    <div className="mac-monitor">
                      <div className="notch"></div>
                      <div className="screen">
                        <iframe
                          src={data.url}
                          title="description"
                          width="100%"
                          height="100%"
                        ></iframe>
                      </div>
                      <div className="base">
                        <img
                          src="https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png"
                          alt="Apple Logo"
                          className="apple-logo"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div
                  className="col-9  mb-5 position-relative"
                  style={{ textAlign: "justify" }}
                >
                  <p>Year: {data.year}</p>
                  <p>Time Period: {data.period}</p>
                  <p>{data.notes}</p>
                  {data.url !== "" ? (
                    <p>
                      Link:{" "}
                      <a href={data.url} style={{ color: "blue" }}>
                        {data.url}
                      </a>
                    </p>
                  ) : null}
                </div>
              </div>

              {end ? null : (
                <div className="row align-end">
                  <div
                    className="col"
                    data-aos="fade-down"
                    data-aos-delay="100"
                    data-aos-duration="400"
                    data-aos-offset="-1"
                  >
                    <div className="row">
                      <div className="col arrow">
                        <BsArrowDown
                          // onClick={() => {
                          //   scrollTo(0, offset(next.current).top + 30);
                          // }}
                          style={{ fontSize: "30px" }}
                        ></BsArrowDown>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Section;
