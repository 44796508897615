import React from "react";

import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";

import Bg from "./bg";
import Nav from "./nav";

import { useEffect } from "react";

AOS.init();
function BlogView({ obj }) {
  useEffect(() => {
    // Check if the page has already loaded
    if (document.readyState === "complete") {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, []);

  function dateConvert(date) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(date.seconds);
    const d = new Date(t);
    let text = d.toDateString();
    return text;
  }

  return (
    <>
      <Bg></Bg>
      <Nav color={"black"}></Nav>

      <div
        className="SectionBody"
        style={{
          backgroundColor: "rgba(255,255,255,1)",
        }}
      >
        <table
          style={{
            width: "100%",
          }}
        >
          <tbody>
            <tr>
              <td class="align-top">
                <h6>D</h6>
                <h6>K</h6>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td class="align-middle">
                <div className="row d-flex justify-content-center">
                  <div className="col-8 position-relative">
                    <div className="row ">
                      <div
                        className="col"
                        data-aos="zoom-in"
                        data-aos-delay="100"
                        data-aos-duration="400"
                        data-aos-offset="-1"
                      >
                        <div className="row">
                          <div className="col">
                            <>
                              <div
                                className="col position-relative mx-3 my-5"
                                style={{ textAlign: "justify" }}
                              >
                                <small>{dateConvert(obj.date)}</small>
                                <h3 className="mb-3">{obj.title}</h3>
                                <small>Category: {obj.cat}</small>
                                <br />
                                <br />
                                <div className="row">
                                  <div className="col text-center">
                                    {" "}
                                    {obj.img ? (
                                      <img
                                        src={obj.img}
                                        alt="img"
                                        style={{ height: "400px" }}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                                <div
                                  className="row d-flex justify-content-center"
                                  style={{ fontFamily: "initial" }}
                                >
                                  <div className="col">
                                    <div
                                      className="my-5 "
                                      dangerouslySetInnerHTML={{
                                        __html: obj.desc,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default BlogView;
