import React, { useEffect, useState } from "react";

import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
// components
import Hero from "./hero";

import Bg from "./bg";

AOS.init();
function Work() {
  // const [orient, setOrient] = useState(0);
  const [view, setView] = useState(false);
  const [navview] = useState(false);
  const [color, setColor] = useState("white");

  // console.log(ref.current.style.left);

  function handleScroll() {
    const position = window.pageYOffset;

    if (position >= 3064 && position < 4852) {
      setColor("black");
    } else if (position >= 4856 && position < 6112) {
      setColor("white");
    } else if (position >= 6112 && position < 7992) {
      setColor("black");
    } else if (position >= 7992 && position < 9258) {
      setColor("white");
    } else if (position >= 9258) {
      setColor("black");
    } else {
      setColor("white");
    }

    console.log(color);
  }

  useEffect(() => {
    const onPageLoad = () => {
      setView(true);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      // window.scrollTo(0, 0);
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => {
        window.removeEventListener("load", onPageLoad);
        window.removeEventListener("scroll", handleScroll);
      };
    }
  });

  return (
    <div className="App" style={{ overflowX: "hidden" }}>
      {view ? (
        <>
          <Bg></Bg>

          <Hero navView={navview}></Hero>
          {/* project 1 */}
        </>
      ) : null}
    </div>
  );
}

export default Work;
