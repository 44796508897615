import React, { useState, useEffect } from "react";

import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import dp from "./../../static/img/dp.jpeg";

import { BsArrowDown } from "react-icons/bs";
import LinearProgress from "@mui/material/LinearProgress";
import iitlogo from "./../../static/img/DK/iitlogo.png";

import Nav from "./nav";
import Bg from "./bg";

import Backdrop from "@mui/material/Backdrop";

import Button from "@mui/material/Button";

import GTC_LOGO from "./../../static/img/gtc.jpeg";

AOS.init();
function DK() {
  const work = [
    {
      title: "Software Engineer",
      empType: "Full Time",
      logo: "https://www.summit4success.com/rax/images/business-directory/Grainger-Logo.png",
      company: "GRAINGER",
      startDate: "Jan 2023",
      endDate: "Present",
      location: "Chicago, IL, USA",
      desc: "Working with Grainger as a Software Engineer. My current position focuses mainly on the backend development. I am closely connected to working on the Grainger's website. I am regularly exposed to technologies like Jira, Confluence, Dynatrace, SonarQube, DataDog, Github Actions, MongoBD, Docker, Kubernetes, and AWS services.",

      skills: [
        { tag: "Spring Boot", per: 90 },
        { tag: "DataDog ", per: 70 },
        { tag: "SonarQube", per: 70 },
        { tag: "Github", per: 90 },
        { tag: "Github Actions", per: 90 },
        { tag: "Jira", per: 90 },
        { tag: "Dynatrace", per: 70 },
        { tag: "MongoDB", per: 90 },
        { tag: "Kubernetes", per: 80 },
        { tag: "AWS Services", per: 80 },
      ],
      img: [],
    },
    {
      title: "Co Founder and Full Stack Developer",
      empType: "Part Time",
      logo: GTC_LOGO,
      company: "GANGA TECHNOCAST",
      startDate: "May 2023",
      endDate: "Present",
      location: "Rajkot, Gujarat, India",
      desc: "Being a Co Founder of Ganga Technocast. I created a company website that showcases the company's services and history. I made the website dynamic by giving the feature of blogs and easy to update the information on the website.",

      skills: [
        { tag: "Express JS", per: 90 },
        { tag: "FirebaseDB ", per: 80 },
        { tag: "Email JS", per: 70 },
        { tag: "Github", per: 90 },
        { tag: "Github Actions", per: 90 },
        { tag: "Jira", per: 90 },
        { tag: "Docker", per: 80 },
      ],
      img: [],
    },
    {
      title: "Full Stack Developer",
      empType: "Part Time",
      logo: "https://firebasestorage.googleapis.com/v0/b/portfolio-a5317.appspot.com/o/Images%2FAsset%206logo2.png?alt=media&token=3e9d7d01-fd80-425f-a75a-10ef28fa8df7",
      company: "KAIZEN INFRA",
      startDate: "May 2022",
      endDate: "Present",
      location: "Rajkot, Gujarat, India",
      desc: "The task was to display the construction projects the company had worked and create a platform where potential employees can submit their resume. The project is built on React and backend is handled by firebase. Additional technologies used are material UI, Tailwind, and Bootstrap. The application also provides an admin portal wherein the managers can add or delete the items such as projects, new, videos, View Resumes and write an article. The workflow of the application is automated by github and hosted via fiabase.",

      skills: [
        { tag: "React.js", per: 90 },
        { tag: "Firebase ", per: 80 },
        { tag: "Bootstrap", per: 90 },
        { tag: "GitHub", per: 90 },
        { tag: "Material UI", per: 70 },
      ],
      img: [],
    },
    {
      title: "Full Stack Developer",
      empType: "Self",
      logo: "",
      company: "Self Project",
      startDate: "Feb 2021",
      endDate: "Nov 2021",
      location: "United States",
      desc: "I worked on creating a web application for my father's company Modi Care in order to manage the inventory. The application was named Alchemeo and it focused on helping the retailers create and share invoices and smart inventory management. \n The application was backed by Django Framework and used SQL as its database. Here the UI designs were made using Bootstraps.",
      skills: [
        { tag: "Django", per: 90 },
        { tag: "MySql", per: 70 },
        { tag: "Bootstrap", per: 80 },
        { tag: "GitHub", per: 80 },
      ],
      img: [],
    },
    {
      title: "Community Desk Assistant",
      empType: "Part Time",
      logo: "https://pbs.twimg.com/profile_images/1124042679468404736/vEMX9YAx_400x400.png",
      company: "Illinois Institute of Technology",
      startDate: "May 2022",
      endDate: "Present",
      location: "Chicago, Illinois, United States",
      desc: "Working in the Residence Life Office to provide support and security in the residence halls.",

      skills: [
        { tag: "Communication", per: 80 },
        { tag: "Security", per: 90 },
        { tag: "Customer Service", per: 90 },
      ],
      img: [],
    },
    {
      title: "Full Stack Developer",
      empType: "Part Time",
      logo: "https://media-exp1.licdn.com/dms/image/C4E0BAQGs0niGg3jh1g/company-logo_200_200/0/1611327171208?e=2147483647&v=beta&t=MC7h-Xjkxlutu3_2PZAy0-9nQ3pl6b_F9V8u_NfS5CY",
      company: "Airblock Technologies",
      startDate: "Sept 2021",
      endDate: "May 2022",
      location: "United States",
      desc: "Created and devised front end designs for Airblock platform leveraging HTML, CSS and Javascript languages with AWS tools for backend such as Cognito, Amplify and S3.\n Devised an UI/UX design for Airblock platform to facilitate easier user experience with professional language. Worked directly with founders to develop the product.",

      skills: [
        { tag: "React.js", per: 90 },
        { tag: "AWS Tools ", per: 70 },
        { tag: "Bootstrap", per: 90 },
        { tag: "GitHub", per: 80 },
        { tag: "Material UI", per: 70 },
      ],
      img: [],
    },

    {
      title: "Full Stack Developer",
      empType: "Self",
      logo: "",
      company: "Self Project",
      startDate: "Jul 2020",
      endDate: "May 2021",
      location: "India",
      desc: "One of four founders of Just Ask (https://github.com/Divyanshukalola/JustAsk-Final). Just Ask is a startup for providing services from Exerpt Q&A to Study Materials. We aim to provide educational services to students who feel Study Materials is costly.",
      skills: [
        { tag: "Django", per: 90 },
        { tag: "MySql", per: 70 },
        { tag: "Bootstrap", per: 80 },
        { tag: "GitHub", per: 80 },
      ],
      img: [],
    },
    {
      title: "Computer Engineer",
      empType: "Self",
      logo: "",
      company: "Self Project",
      startDate: "May 2022",
      endDate: "Present",
      location: "India - US",
      desc: "Building a low cost automation system that can fit on top of the exsisting electrical infrastructure. This will be user friendly and easy to install.",
      skills: [
        { tag: "C Language", per: 90 },
        { tag: "MySql", per: 70 },
        { tag: "MQTT", per: 90 },
        { tag: "React.JS", per: 90 },
        { tag: "PCB Designa", per: 90 },
        { tag: "Circuit Design", per: 93 },
        { tag: "ESP32 and ESP8266", per: 90 },
      ],
      img: [],
    },
  ];

  const courses = [
    {
      courseName: "ECE 211",
      courseProff: "",
      courseTitle: "Circuit Analysis I",
      courseDetails:
        "Ohm's Law, Kirchhoff's Laws, and network element voltage-current relations. Application of mesh and nodal analysis to circuits. Dependent sources, operational amplifier circuits, superposition, Thevenin's and Norton's Theorems, maximum power transfer theorem. Transient circuit analysis for RC, RL, and RLC circuits. Introduction to Laplace Transforms. Laboratory experiments include analog and digital circuits; familiarization with test and measurement equipment; combinational digital circuits; familiarization with latches, flip-flops, and shift registers; operational amplifiers; transient effects in first-order and second-order analog circuits; PSpice software applications.",
    },
    {
      courseName: "ECE 213",
      courseProff: "",
      courseTitle: "Circuit Analysis II",
      courseDetails:
        "Sinusoidal excitation and phasors. AC steady-state circuit analysis using phasors. Complex frequency, network functions, pole-zero analysis, frequency response, and resonance. Two-port networks, transformers, mutual inductance, AC steady-state power, RMS values, introduction to three-phase systems and Fourier series. Design-oriented experiments include counters, finite state machines, sequential logic design, impedances in AC steady-state, resonant circuits, two-port networks, and filters. A final project incorporating concepts from analog and digital circuit design will be required.",
    },
    {
      courseName: "ECE 218",
      courseProff: "",
      courseTitle: "Digital Systems",
      courseDetails:
        "Number systems and conversions, binary codes, and Boolean algebra. Switching devices, discrete and integrated digital circuits, analysis and design of combinational logic circuits. Karnaugh maps and minimization techniques. Counters and registers. Analysis and design of synchronous sequential circuits.",
    },
    {
      courseName: "ECE 242",
      courseProff: "",
      courseTitle: "Digital Computers and Computing",
      courseDetails:
        "Basic concepts in computer architecture, organization, and programming, including: integer and floating point number representations, memory organization, computer processor operation (the fetch/execute cycle), and computer instruction sets. Programming in machine language and assembly language with an emphasis on practical problems. Brief survey of different computer architectures.",
    },
    {
      courseName: "ECE 311",
      courseProff: "",
      courseTitle: "Engineering Electronics",
      courseDetails:
        "Physics of semiconductor devices. Diode operation and circuit applications. Regulated power supplies. Bipolar and field-effect transistor operating principles. Biasing techniques and stabilization. Linear equivalent circuit analysis of bipolar and field-effect transistor amplifiers. Laboratory experiments reinforce concepts.",
    },
    {
      courseName: "ECE 441",
      courseProff: "",
      courseTitle: "Smart and Connected Embedded System Design",
      courseDetails:
        "This is a culminating major design experience course that involves smart and connected system applications including Internet of Things, healthcare system, artificial intelligence and machine vision, wireless sensor network, smart security system, smart city, smart power grid, smart power electronic devices, smart transportation, factory automation, agriculture automation, and home automation. Smart and connected system entails human machine interface, embedded computing, interrupt/exception handling, fault detection and recovery, standard and special peripheral interfacing to sensors and actuators, hardware and software codesign for data acquisition, encryption/decryption for secure system, information processing, data storage, and network communication protocols.",
    },
    {
      courseName: "ECE 485",
      courseProff: "",
      courseTitle: "Computer Organization and Design",
      courseDetails:
        "This course provides the students with understanding of the fundamental concepts of computer architecture, organization, and design. It focuses on relationship between hardware and software and its influence on the instruction set and the underlying Central Processing Unit (CPU). The structural design of the CPU in terms of datapath and control unit is introduced. The technique of pipelining and hazard management are studied. Advanced topics include instruction level parallelism, memory hierarchy and cache operations, virtual memory, parallel processing, multiprocessors and hardware security. The end to end design of a typical computer system in terms of the major entities including CPU, cache, memory, disk, I/O, and bus with respect to cost/performance trade-offs is also covered.",
    },
    {
      courseName: "CS 115",
      courseProff: "",
      courseTitle: "Object-Oriented Programming I",
      courseDetails:
        "Introduces the use of a high-level object-oriented programming language as a problem-solving tool, including basic data structures and algorithms, object-oriented programming techniques, and software documentation. Designed for students who have had little or no prior experience with computer programming.",
    },
    {
      courseName: "CS 116",
      courseProff: "",
      courseTitle: "Object-Oriented Programming II",
      courseDetails:
        "Introduces more advanced elements of object-oriented programming, including dynamic data structures, recursion, searching and sorting, and advanced object-oriented programming techniques.",
    },
    {
      courseName: "CS 330",
      courseProff: "",
      courseTitle: "Discrete Structures",
      courseDetails:
        "Introduction to the use of formal mathematical structures to represent problems and computational processes. Topics covered include Boolean algebra, first-order logic, recursive structures, graphs, and abstract language models.",
    },
    {
      courseName: "CS 331",
      courseProff: "",
      courseTitle: "Data Structures and Algorithms",
      courseDetails:
        "Implementation and application of the essential data structures used in computer science. Analysis of basic sorting and searching algorithms and their relationship to these data structures. Particular emphasis is given to the use of object-oriented design and data abstraction in the creation and application of data structures.",
    },
    {
      courseName: "CS 351",
      courseProff: "",
      courseTitle: "Systems Programming",
      courseDetails:
        "Examines the components of sophisticated multilayer software systems, including device drivers, systems software, applications interfaces, and user interfaces. Explores the design and development of interrupt-driven and event-driven software.",
    },
    {
      courseName: "CS 450",
      courseProff: "",
      courseTitle: "Operating Systems",
      courseDetails:
        "Introduction to operating system concepts-including system organization for uniprocessors and multiprocessors, scheduling algorithms, process management, deadlocks, paging and segmentation, files and protection, and process coordination and communication.",
    },
    {
      courseName: "CS 442",
      courseProff: "",
      courseTitle: "Mobile Applications Development",
      courseDetails:
        "Students will learn a variety of software engineering techniques and design patterns to assist in the rapid development and prototyping of applications, leveraging frameworks and APIs provided by current mobile development platforms (such as Android and iOS). Application lifecycles, data management and persistence mechanisms, and user interface design, among other topics, will be covered. Industry speakers will be invited to speak about best practices. Students (individually or in teams) will take ideas from concept to final implementation and will present their work at the end of the semester. When appropriate, students may take the additional step of deploying their work on the appropriate application marketplace(s).",
    },
    {
      courseName: "CS 484",
      courseProff: "",
      courseTitle: "Introduction to Machine Learning",
      courseDetails:
        "An introduction to machine learning concepts and algorithms, including classification, clustering, and regression. Topics include k-means clustering, nearest neighbors classification, decision trees, naive Bayes, logistic regression, support vector machines, and neural networks. Special focus will be on practical aspects of machine learning, including data preparation, experimental design, and modern tools for building machine learning systems. Basic probability theory knowledge is required.",
    },
    {
      courseName: "ECE 308",
      courseProff: "",
      courseTitle: "Signals and Systems",
      courseDetails:
        "Time and frequency domain representation of continuous and discrete time signals. Introduction to sampling and sampling theorem. Time and frequency domain analysis of continuous and discrete linear systems. Fourier series convolution, transfer functions. Fourier transforms, Laplace transforms, and Z-transforms.",
    },
    {
      courseName: "ECE 443",
      courseProff: "",
      courseTitle: "Introduction to Computer Cyber Security",
      courseDetails:
        "This course gives students a clear understanding of computer and cyber security as threats and defense mechanisms backed by mathematical and algorithmic guarantees. Key topics covered include introductory number theory and complexity theory, cryptography and applications, system security, digital forensics, software and hardware security, and side-channel attacks. Course projects will provide hand-on experiences on languages, libraries, and tools supporting state-of-theart cryptography applications.",
    },
    {
      courseName: "ECE 429",
      courseProff: "",
      courseTitle: "Introduction to VLSI Design",
      courseDetails:
        "Processing, fabrication, and design of Very Large Scale Integration (VLSI) circuits. MOS transistor theory, VLSI processing, circuit layout, layout design rules, layout analysis, and performance estimation. The use of computer aided design (CAD) tools for layout design, system design in VLSI, and application-specific integrated circuits (ASICs). In the laboratory, students create, analyze, and simulate a number of circuit layouts as design projects, culminating in a term design project.",
    },
  ];
  // 1582
  const ref2 = React.useRef();
  const [color, setColor] = useState("white");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [view, setView] = useState(false);
  console.log(view + scrollPosition);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
      if (position >= 1484) {
        setColor("black");
      } else {
        setColor("white");
      }
      ref2.current.style.backgroundColor = `rgba(48, 48, 48, ${
        0.2 + (position / 100) * 0.5
      })`;
    };

    ref2.current.style.backgroundColor = `rgba(48, 48, 48, 0.2)`;
    window.addEventListener("scroll", handleScroll, { passive: true });

    const onPageLoad = () => {
      setView(true);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      window.scrollTo(0, 0);
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [open, setOpen] = React.useState(-1);

  const handleToggle = (value) => {
    setOpen(value);
  };


  function splitList(list) {
    // Define the maximum number of skills per row
    const skillsPerRow = 5;

    // Calculate the number of rows needed
    const numberOfRows = Math.ceil(list.length / skillsPerRow);

    // Create an array of rows, where each row is an array of skills
    const skillRows = Array.from({ length: numberOfRows }, (_, rowIndex) =>
      list.slice(rowIndex * skillsPerRow, (rowIndex + 1) * skillsPerRow)
    );

    return skillRows;
  }

  return (
    <>
      <Bg></Bg>
      <Nav active="DK" color={color}></Nav>
      <div
        className="row Hero"
        ref={ref2}
        style={{ height: "100vh", maxWidth: "100vw" }}
      >
        <div className="col">
          <div className="sticky-top">
            <table style={{ height: "100vh", width: "100vw" }}>
              <tbody>
                <tr>
                  <td className="align-top">
                    {/* top */}
                    <div className="row">
                      <div className="col">
                        <h6>D</h6>
                        <h6>K</h6>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td className="">
                    {/* middle */}
                    <div className="row">
                      {/* left */}
                      <div className="col-5 align-self-center">
                        <div
                          className="row justify-content-center"
                          data-aos="fade-down"
                          data-aos-delay="1400"
                          data-aos-duration="600"
                          data-aos-easing="linear"
                          data-aos-offset="0"
                        >
                          <div className="col ">
                            <img
                              src={dp}
                              alt=""
                              height={"500px"}
                              className="dp"
                            />
                          </div>
                        </div>
                      </div>
                      {/* right */}
                      <div className="col-6 align-self-center">
                        <h1 className="dpName ">
                          <span
                            data-aos="fade-down"
                            data-aos-delay="1000"
                            data-aos-duration="600"
                            data-aos-offset="0"
                            data-aos-easing="linear"
                          >
                            Divyanshu
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="1200"
                            data-aos-duration="600"
                            data-aos-easing="linear"
                            data-aos-offset="0"
                          >
                            Kalola
                          </span>
                        </h1>
                        <h1 className="dpText">
                          <span
                            data-aos="fade-down"
                            data-aos-delay="1700"
                            data-aos-duration="600"
                            data-aos-easing="linear"
                            data-aos-offset="0"
                          >
                            I've created
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="1750"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            products
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="1800"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            for businesses like
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="1850"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            Kaizen
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="1900"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            and Airblock Technologies
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="1950"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            as a seasoned
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="2000"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            software expert.
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="2050"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            My area of expertise
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="2100"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            is developing applications
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="2150"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            that are unique
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="2200"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            to my clients' business
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="2250"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            requirements.
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="2300"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            I have experience
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="2350"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            managing databases,
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="2400"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            servers,
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="2450"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            and front-end/back-end
                          </span>{" "}
                          <span
                            data-aos="fade-down"
                            data-aos-delay="2500"
                            data-aos-duration="600"
                            data-aos-offset="0"
                          >
                            web applications.
                          </span>
                        </h1>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  {" "}
                  <td className="align-bottom">
                    {" "}
                    {/* bottom */}
                    <div className="row align-end">
                      <div
                        className="col"
                        data-aos="fade-down"
                        data-aos-delay="1300"
                        data-aos-duration="400"
                        data-aos-offset="0"
                      >
                        <div className="row">
                          <div className="col arrow">
                            <BsArrowDown
                              onClick={() => {
                                window.scrollTo(0, 1582);
                              }}
                              style={{ fontSize: "30px" }}
                            ></BsArrowDown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        className="SectionBody"
        style={{ height: "100vh", backgroundColor: "rgb(255, 255, 255)" }}
      >
        <div className="row">
          <div className="col my-3">
            <h6>DIVYANSHU'S EDUCATION</h6>
          </div>
        </div>

        <table style={{ height: "80vh", width: "100vw" }}>
          <tbody>
            <tr>
              <td class="align-middle">
                <div className="row mb-5">
                  <div className="col">
                    <p
                      className="title"
                      data-aos="fade-down"
                      data-aos-delay="50"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out-cubic"
                    >
                      Education
                    </p>
                  </div>
                </div>
                <div className="row mt-5">
                  <div
                    className="col-5 mt-5"
                    data-aos="fade-right"
                    data-aos-delay="100"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out-cubic"
                  >
                    <img src={iitlogo} height="100px" alt="" />
                  </div>
                  <div className="col" style={{ textAlign: "left" }}>
                    <p
                      style={{ fontSize: "40px" }}
                      data-aos="fade-left"
                      data-aos-delay="100"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out-cubic"
                    >
                      Illinois Institute of Technology
                    </p>
                    <p
                      data-aos="fade-left"
                      data-aos-delay="150"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out-cubic"
                    >
                      Bachelor of Engineering - BE, Computer Engineering
                    </p>
                    <p
                      data-aos="fade-left"
                      data-aos-delay="200"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out-cubic"
                    >
                      2019 - 2022
                    </p>
                    <p
                      data-aos="fade-left"
                      data-aos-delay="250"
                      data-aos-duration="500"
                      data-aos-easing="ease-in-out-cubic"
                    >
                      Recognition: 1st Place, Best Team Presentation and
                      Undergraduate Research and Design
                    </p>
                  </div>
                </div>

                {/* courses and teachers */}

                <div
                  className="row mt-5"
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-out-cubic"
                >
                  <div className="col">
                    <p>COURSES</p>
                  </div>
                </div>
                <div
                  className="row mx-5"
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-out-cubic"
                >
                  <div className="col">
                    {courses.map((obj, index) => {
                      return (
                        <>
                          <Button
                            onClick={() => {
                              handleToggle(index);
                            }}
                          >
                            {obj.courseName}
                          </Button>
                        </>
                      );
                    })}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        className="SectionBody"
        style={{ height: "200vh", backgroundColor: "rgb(255, 255, 255)" }}
      >
        <div className="row mb-3">
          <div className="col my-3">
            <h6>DIVYANSHU'S SKILLS</h6>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p
              className="title"
              data-aos="fade-up"
              data-aos-delay="50"
              data-aos-duration="500"
              data-aos-easing="ease-in-out-cubic"
            >
              Languages
            </p>
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-4 mx-2 position-relative">
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Python
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={95}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              JavaScript
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={85}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              HTML
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={95}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              JSON
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={97}
              />
            </p>
          </div>
          <div className="col-4 mx-2 position-relative">
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Java
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={97}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              C
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={60}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              CSS
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={90}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              x86 Assembly
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={97}
              />
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col mt-5">
            <p
              className="title"
              data-aos="fade-up"
              data-aos-delay="50"
              data-aos-duration="500"
              data-aos-easing="ease-in-out-cubic"
            >
              Technical Skills
            </p>
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-4 mx-2 position-relative">
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Django
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={90}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              React.JS
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={95}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              SolidWorks
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={70}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Fusion 360
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={80}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Unity Game Engine
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={70}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              AWS Tools
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={80}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Google Firebase
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={80}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Apache Cassandra
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={60}
              />
            </p>
          </div>
          <div className="col-4 mx-2 position-relative">
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              MATLAB
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={70}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Linux
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={80}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Machine Learning
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={80}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Flask
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={77}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Adobe Illustrator
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={60}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              GitHub
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={90}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              GraphQL
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={60}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Bootstrap
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={90}
              />
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col mt-5">
            <p
              className="title"
              data-aos="fade-up"
              data-aos-delay="50"
              data-aos-duration="500"
              data-aos-easing="ease-in-out-cubic"
            >
              Hardware Skills
            </p>
          </div>
        </div>
        <div className="row mt-5 d-flex justify-content-center">
          <div className="col-4 mx-2 position-relative">
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Arduino
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={80}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Raspberry Pi
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={95}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              PIC microcontrollers
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={60}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Motorola 68000
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={80}
              />
            </p>
          </div>
          <div className="col-4 mx-2 position-relative">
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              VLSI designing
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={60}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              PSpice
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={70}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              PCB Fabrication
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={75}
              />
            </p>
            <p
              data-aos="fade-left"
              data-aos-delay="1100"
              data-aos-duration="1000"
            >
              Digital Circuit Fabrication and Design
              <LinearProgress
                variant="determinate"
                color="inherit"
                value={90}
              />
            </p>
          </div>
        </div>
      </div>
      <div
        className="SectionBody"
        style={{ backgroundColor: "rgb(255, 255, 255)" }}
      >
        <div className="row">
          <div className="col my-3 mb-5">
            <h6>DIVYANSHU'S WORK</h6>
          </div>
        </div>
        {work.map((obj, index1) => {
          return (
            <>
              <div
                className="row my-5 d-flex justify-content-center"
                style={{ textAlign: "left" }}
              >
                <div className="col-10 my-5 position-relative">
                  <p
                    data-aos="fade-right"
                    data-aos-delay={100 + index1 * 50}
                    data-aos-duration="1000"
                  >
                    SECTION .{index1 + 1}
                  </p>
                  {obj.logo !== "" ? (
                    <p
                      className="title"
                      data-aos="fade-right"
                      data-aos-delay={100 + index1 * 50}
                      data-aos-duration="1000"
                    >
                      <img src={obj.logo} height="70px" alt="logo" />
                    </p>
                  ) : null}

                  <p
                    className="title"
                    data-aos="fade-right"
                    data-aos-delay={100 + index1 * 50}
                    data-aos-duration="1000"
                  >
                    {obj.company} - {obj.empType}
                  </p>
                  <p
                    data-aos="fade-right"
                    data-aos-delay={200 + index1 * 50}
                    data-aos-duration="1000"
                  >
                    Date: {obj.startDate} - {obj.endDate}
                  </p>
                  <p
                    data-aos="fade-right"
                    data-aos-delay={300 + index1 * 50}
                    data-aos-duration="1000"
                  >
                    Position: {obj.title}
                  </p>
                  <p
                    data-aos="fade-right"
                    data-aos-delay={400 + index1 * 50}
                    data-aos-duration="1000"
                  >
                    Location: {obj.location}
                  </p>
                  <p
                    data-aos="fade-right"
                    data-aos-delay={500 + index1 * 50}
                    data-aos-duration="1000"
                  >
                    Description: {obj.desc}
                  </p>

                  {splitList(obj.skills).map((row, rowIndex) => (
                    <div key={rowIndex} className="row">
                      {row.map((skill, index) => (
                        <div
                          key={index}
                          className="col"
                          data-aos="fade-right"
                          data-aos-delay={500 + index * 50}
                          data-aos-duration="1000"
                        >
                          <p>
                            {skill.tag}
                            <LinearProgress
                              variant="determinate"
                              color="inherit"
                              value={skill.per}
                            />
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </>
          );
        })}
        <br />
      </div>

      {courses.map((obj, index) => {
        return (
          <>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={open === index ? true : false}
              onClick={() => {
                setOpen(-1);
              }}
            >
              <div className="row mx-5">
                <div
                  className="col"
                  style={{
                    textAlign: "left",
                  }}
                >
                  <p>
                    <b>Course Code:</b> {obj.courseName}
                  </p>
                  <p>
                    <b>Course Name: </b> {obj.courseTitle}
                  </p>
                  <p>
                    <b>Course Description: </b>
                    {obj.courseDetails}
                  </p>
                </div>
              </div>
            </Backdrop>
          </>
        );
      })}
    </>
  );
}

export default DK;
