import React, { useState } from "react";

import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import TextField from "@mui/material/TextField";
import Bg from "./bg";
import Nav from "./nav";
import { Editor } from "@tinymce/tinymce-react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { useForm } from "react-hook-form";

import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase-config";
import { v4 } from "uuid";
import { useEffect } from "react";

import Switch from "@mui/material/Switch";

import { ref, uploadBytes, getDownloadURL } from "@firebase/storage";
import { storage } from "../../firebase-config";
import AdminEdit from "./adminedit";

import readData from "./../../components/functoins/DB";

AOS.init();
function Admin() {
  const [file, setFile] = useState(null);
  const [blog, setblog] = useState([]);
  const editorRef = React.useRef(null);
  const { register, getValues } = useForm({});
  const [id] = useState(v4());
  const dataCollectionRef1 = collection(db, "Blog");
  const [loading, setLoading] = useState(false);
  const [desc, setDesc] = useState("");
  const [edit, setedit] = useState(false);
  const [odj, setodj] = useState({});
  const [state, setState] = useState("featured");

  useEffect(() => {
    readData("Blog").then((e) => {
      setblog(e);
    });
    if (document.readyState === "complete") {
    } else {
      return;
    }
  }, []);
  async function writeData() {
    if (
      file === null ||
      getValues("desc") === undefined ||
      getValues("title") === undefined ||
      getValues("date") === undefined ||
      getValues("cat") === undefined ||
      getValues("featured") === undefined
    ) {
      alert("You forgot something.");
      return;
    }
    setLoading(true);

    const imageRef = ref(storage, `Blog/${id}`);

    await uploadBytes(imageRef, file).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        addDoc(dataCollectionRef1, {
          title: getValues("title"),
          desc: desc,
          date: getValues("date"),
          publish: getValues("publish"),
          img: url,
          cat: getValues("cat"),
          featured: getValues("featured"),
          blogID: id,
        });
      });
    });

    await setTimeout(function () {
      setLoading(false);
      window.location.reload(false);
    }, 5000);
  }
  function dateConvert(date) {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(date.seconds);
    const d = new Date(t);
    let text = d.toDateString();
    return text;
  }

  function organiseData(array, size, cat) {
    var perChunk = size; // items per chunk
    var inputArray;
    if (cat === "all") {
      inputArray = array;
    } else if (cat === "featured") {
      inputArray = array.filter((e) => e.featured === true);
    } else {
      inputArray = array.filter((e) => e.cat === cat);
    }

    var result = inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    return result;
  }
  function getDescription(text) {
    const str = text;

    let doc = new DOMParser().parseFromString(str, "text/html");

    return doc.querySelector("p").textContent.length > 200
      ? doc.querySelector("p").textContent.substring(0, 200) + " ..."
      : doc.querySelector("p").textContent;
  }

  function getCat() {
    const res = Object.values(
      blog.reduce((a, { cat }) => {
        a[cat] = { cat };
        return a;
      }, {})
    );

    console.log(res);
    return res;
  }

  return (
    <>
      {edit ? (
        <AdminEdit odj={odj}></AdminEdit>
      ) : (
        <>
          <Bg></Bg>
          <Nav color={"black"}></Nav>

          <div
            className="SectionBody"
            style={{
              backgroundColor: "rgba(255,255,255,1)",
            }}
          >
            <table
              style={{
                height: "90vh",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td class="align-top">
                    <h6>D</h6>
                    <h6>K</h6>
                  </td>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td class="align-middle">
                    <div className="row d-flex justify-content-center">
                      <div className="col-10 position-relative">
                        <div
                          className="row card-group d-flex justify-content-center"
                          style={{ fontFamily: "inherit" }}
                        >
                          <div className="col position-relative mx-3">
                            <div className="row mb-4 ">
                              <div className="col">
                                <TextField
                                  id="outlined-basic"
                                  label="Title"
                                  variant="outlined"
                                  {...register("title")}
                                  inputProps={{
                                    style: { fontFamily: "Share Tech Mono" },
                                  }}
                                  InputLabelProps={{
                                    style: { fontFamily: "Share Tech Mono" },
                                  }}
                                />
                              </div>
                              <div className="col">
                                <TextField
                                  id="outlined-basic"
                                  label="Date"
                                  variant="outlined"
                                  {...register("date", {
                                    valueAsDate: true,
                                  })}
                                  inputProps={{
                                    style: { fontFamily: "Share Tech Mono" },
                                  }}
                                  InputLabelProps={{
                                    style: { fontFamily: "Share Tech Mono" },
                                  }}
                                />
                              </div>
                              <div className="col">
                                <TextField
                                  id="outlined-basic"
                                  label="Category"
                                  variant="outlined"
                                  {...register("cat")}
                                  inputProps={{
                                    style: { fontFamily: "Share Tech Mono" },
                                  }}
                                  InputLabelProps={{
                                    style: { fontFamily: "Share Tech Mono" },
                                  }}
                                />
                              </div>
                              <div className="col">
                                <input
                                  type="file"
                                  id="upload"
                                  hidden
                                  onChange={(event) => {
                                    setFile(event.target.files[0]);
                                  }}
                                />
                                <label
                                  for="upload"
                                  className={
                                    file !== null
                                      ? "btn btn-success"
                                      : "btn btn-secondary"
                                  }
                                >
                                  {file !== null ? file.name : "Title Picture"}
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <Editor
                                  onInit={(evt, editor) =>
                                    (editorRef.current = editor)
                                  }
                                  onChange={(e) => {
                                    register("desc", {
                                      value: e.target.getContent(),
                                    });
                                    setDesc(e.target.getContent());
                                    console.log(desc);
                                  }}
                                  initialValue="<p>This is the initial content of the editor.</p>"
                                  init={{
                                    height: 480,
                                    max_height: 800,
                                    menubar: true,
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview",
                                      "anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "autoresize",
                                      "media",
                                      "visualchars",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                      "table",
                                      "template",
                                    ],

                                    toolbar:
                                      "blockquote bold italic underline forecolor backcolor | subscript superscript alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent lineheight | " +
                                      "h1 h2 h3 h4 h5 h6 |fontsizeselect| removeformat table tableinsertdialog tablecellprops tableprops advtablerownumbering | fullscreen",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:15px }",
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row my-3">
                              <div className="col">
                                Featured:{" "}
                                <Switch
                                  {...register("featured")}
                                  defaultChecked
                                />
                              </div>
                              <div className="col">
                                Publish: <Switch {...register("publish")} />
                              </div>
                            </div>
                            <div className="row mt-4">
                              <div className="col">
                                {loading ? (
                                  <button>Uploading your data ... </button>
                                ) : (
                                  <button onClick={writeData}>Submit</button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="SectionBody"
            style={{
              backgroundColor: "rgba(255,255,255,1)",
            }}
          >
            <table
              style={{
                height: "100vh",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td class="align-middle">
                    <div className="row d-flex justify-content-center">
                      <div className="col-7 position-relative">
                        <div className="row " style={{ fontFamily: "inherit" }}>
                          <hr />
                          <div className="row justify-content-center">
                            <div
                              className={
                                state === "featured"
                                  ? "col-2 mt-2 position-relative active"
                                  : "col-2 mt-2 position-relative"
                              }
                            >
                              <button
                                className="plainbut"
                                onClick={() => {
                                  setState("featured");
                                }}
                              >
                                Featured
                              </button>
                            </div>
                            <div
                              className={
                                state === "all"
                                  ? "col-2 mt-2 position-relative active"
                                  : "col-2 mt-2 position-relative"
                              }
                            >
                              <button
                                className="plainbut"
                                onClick={() => {
                                  setState("all");
                                }}
                              >
                                All
                              </button>
                            </div>
                            {getCat().map((cat) => {
                              return (
                                <div
                                  className={
                                    state === cat.cat
                                      ? "col-2 mt-2 position-relative active"
                                      : "col-2 mt-2 position-relative"
                                  }
                                >
                                  <button
                                    className="plainbut"
                                    onClick={() => {
                                      setState(cat.cat);
                                    }}
                                  >
                                    {cat.cat}
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                          <hr />

                          {organiseData(blog, 2, state).map((obj1) => {
                            return (
                              <div className="row mt-4">
                                {obj1.map((obj) => {
                                  return (
                                    <>
                                      <div
                                        className="col-6"
                                        style={{ textAlign: "left" }}
                                      >
                                        <Card sx={{ maxWidth: "inherit" }}>
                                          {obj.img ? (
                                            <CardMedia
                                              component="img"
                                              alt="img"
                                              height="200"
                                              image={obj.img}
                                            />
                                          ) : null}

                                          <CardContent>
                                            <Typography
                                              variant="caption"
                                              display="block"
                                            >
                                              {obj.cat}
                                            </Typography>
                                            <Typography
                                              gutterBottom
                                              variant="h5"
                                              component="div"
                                            >
                                              {obj.title}
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              color="text.secondary"
                                            >
                                              {getDescription(obj.desc)}
                                            </Typography>
                                            <br />
                                            <div className="row">
                                              <div className="col">
                                                <Typography
                                                  variant="caption"
                                                  display="block"
                                                  gutterBottom
                                                >
                                                  {dateConvert(obj.date)}
                                                </Typography>
                                              </div>
                                              <div
                                                className="col"
                                                style={{ textAlign: "right" }}
                                              >
                                                <Button
                                                  size="small"
                                                  onClick={() => {
                                                    setodj(obj);
                                                    setedit(true);
                                                  }}
                                                >
                                                  Edit
                                                </Button>
                                              </div>
                                            </div>
                                          </CardContent>
                                          <CardActions></CardActions>
                                        </Card>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}

export default Admin;
