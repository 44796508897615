import "./../../static/animate/animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import React from "react";

AOS.init();
function Bg() {
  return (
    <div
      className="row HeroBG"
      data-aos="fade-in"
      data-aos-delay="3000"
      data-aos-duration="2000"
    >
      <div className="col-2 cl1"></div>
      <div className="col-2 cl2"></div>
      <div className="col-2 cl3"></div>
      <div className="col-2 cl4"></div>
      <div className="col-2 cl5"></div>
      <div className="col-2 cl6"></div>
    </div>
  );
}

export default Bg;
