// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAAu0gS6uvNCncuCFm7HCxLF1HLXXp6RK0",
  authDomain: "portfolio-a5317.firebaseapp.com",
  projectId: "portfolio-a5317",
  storageBucket: "portfolio-a5317.appspot.com",
  messagingSenderId: "52338704824",
  appId: "1:52338704824:web:d9ddee309e224c13244f4d",
  measurementId: "G-SFCJ51CZ5K",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
// const provider = new GoogleAuthProvider();

// const signInWithGoogle = () => {
//   signInWithPopup(auth, provider);
// };